import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Container, Block } from '../primitives';
import Card from './CoachCard';

const QUERY = graphql`
  query {
    staff: allSanityStaff(sort: { fields: order, order: ASC }) {
      edges {
        ...Staff
      }
    }
  }
`;

const Section = styled.section`
  background: #f6f6f4;
`;

const Heading = styled.div`
  text-align: center;
  margin: 0 0 3rem 0;
  h2 {
    font-size: 2.5rem;
    color: #241c15;
    margin: 0 0 1rem 0;
    line-height: 1.3;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-row-gap: 2rem;
`;

const Coaches = () => {
  //
  const {
    staff: { edges: allStaff },
  } = useStaticQuery(QUERY);

  return (
    <Section>
      <Container>
        <Block>
          <Heading>
            <h2>Coaches</h2>
          </Heading>
          <Grid>
            {allStaff.map(staff => (
              <div key={staff.node.id} id={staff.node.id}>
                <Card data={staff} />
              </div>
            ))}
          </Grid>
        </Block>
      </Container>
    </Section>
  );
};

export default Coaches;
