import React from 'react';
import styled from 'styled-components';
import { Container, Block } from '../primitives';
import HeroImage from './StoryHeroImage';
import ExteriorImage from './ExteriorImage';
import InteriorImage from './InteriorImage';
import CommunityImage from './CommunityImage';

const Section = styled.section.attrs({ className: 'relative bg-white' })`
  h1,
  h2 {
    font-size: 2.5rem;
    color: #241c15;
    margin: 0 0 1rem 0;
    line-height: 1.3;
  }
  p {
    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: 300;
    margin: 0 0 2rem 0;
  }
`;

const HeroImageFrame = styled.div`
  position: relative;
  height: 680px;
`;

const InteriorImageWrapper = styled.div`
  height: 280px;
  width: 100%;
  border: 0.5rem #ffffff solid;
  outline: 1px #a0aec0 solid;
  overflow: hidden;
`;

const ExteriorImageWrapper = styled.div`
  height: 280px;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
`;

const StoryWrapper = styled.div`
  #grid-top-copy {
    grid-area: topCopy;
    padding: 2rem 2rem 0;
    span {
      font-weight: 500;
    }
  }
  #grid-interior {
    grid-area: interior;
    @media (min-width: 500px) {
      padding: 2rem;
    }
  }
  #grid-exterior {
    display: none;
    grid-area: exterior;
    @media (min-width: 500px) {
      padding: 2rem;
    }
  }
  #grid-bottom-copy {
    grid-area: bottomCopy;
    padding: 2rem;
  }
  position: relative;
  margin: 0 auto;
  max-width: 860px;
  @media (min-width: 800px) {
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: 1fr 400px;
    grid-template-areas:
      'topCopy interior'
      'exterior bottomCopy';

    #grid-top-copy {
      padding: 4rem 2rem 0 3rem;
    }
    #grid-bottom-copy {
      align-self: end;
    }
    #grid-interior {
      padding: 4rem 2rem 3rem;
    }
    #grid-exterior {
      display: block;
      padding: 2rem 2rem 4rem;
    }
  }
`;

const StoryWrapper2 = styled.div`
  max-width: 860px;
  margin: 0 auto;
  @media (min-width: 800px) {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-column-gap: 2rem;
  }
`;

const CommunityImageWrapper = styled.div.attrs({ className: 'shadow-lg' })`
  height: 100%;
`;

const SectionWrapper = styled.div`
  background: #ffd700;
  @media (min-width: 800px) {
    margin: 4rem 0 0 0;
  }
`;

const Story = () => {
  //
  return (
    <Section>
      <HeroImageFrame>
        <HeroImage />
      </HeroImageFrame>
      <StoryWrapper>
        <div id="grid-top-copy">
          <h1>Family</h1>
          <p>
            <span>Sea Dragons Aquatic Academy</span> is the representation of a family. Beginning in 2017, SDAA teaches
            at multiple international schools as well as a fully facilitated indoor pool built to stellar standards.
          </p>
        </div>
        <div id="grid-interior">
          <InteriorImageWrapper>
            <ExteriorImage />
          </InteriorImageWrapper>
        </div>
        <div id="grid-bottom-copy">
          <p>
            The indoor pool allows training all year round, whether rain or shine. It is the baby of a passionate coach
            and a couple whose children are competitive swimmers. Their instilled ideology and attitude have allowed
            their children to flourish in ways otherwise impossible. This motivated them to build a swimming school for
            everyone.
          </p>
        </div>
        <div id="grid-exterior">
          <ExteriorImageWrapper>
            <InteriorImage />
          </ExteriorImageWrapper>
        </div>
      </StoryWrapper>
      <SectionWrapper>
        <Block>
          <Container>
            <StoryWrapper2>
              <div>
                <h2>Be part of a Community</h2>
                <p>
                  Joining Sea Dragons is like joining a community; it’s gaining new connections and forging new
                  friendships.
                </p>
              </div>
              <div>
                <CommunityImageWrapper>
                  <CommunityImage />
                </CommunityImageWrapper>
              </div>
            </StoryWrapper2>
          </Container>
        </Block>
      </SectionWrapper>
    </Section>
  );
};

export default Story;
