import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { SEO } from '../components/primitives';
import Story from '../components/_about/Story';
import Coaches from '../components/_about/Coaches';
// import Philosophy from '../components/_about/Philosophy';

const pageTransition = {
  initial: {},
  enter: {},
  exit: {},
};

const About = ({ location: { pathname } }) => {
  //

  return (
    <motion.article
      className="relative"
      key={pathname}
      initial="initial"
      animate="enter"
      exit="exit"
      variants={pageTransition}
    >
      <SEO title="The SDAA Story" description="About SDAA. Our story and philosophy." />
      <Story />
      {/* <Philosophy /> */}
      {/* <Coaches /> */}
    </motion.article>
  );
};

export default About;

About.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};
