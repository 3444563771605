/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import { MarkdownStyles } from '../primitives';
import Facebook from '../svg/Facebook';
import Instagram from '../svg/Instagram';
import LinkedIn from '../svg/LinkedIn';
import Twitter from '../svg/Twitter';

const ImageSharp = styled(Img)`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
  hr {
    border-bottom: 1px #bee3f8 solid;
    margin: 3rem 0 0 0;
  }
`;

const CardContainer = styled.div`
  background: #ffffff;
  max-width: 320px;
  padding: 1rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin: 0 auto;
`;

const ProfileImageWrapper = styled.div`
  height: 280px;
  width: 100%;
  border: 0.5rem #ffffff solid;
  outline: 1px #a0aec0 solid;
  overflow: hidden;
`;

const BodyWrapper = styled.div`
  padding: 2rem 2rem 2rem 1rem;
  h2 {
    font-size: 1.25rem;
    font-weight: 500;
  }
  p {
    font-size: 0.875rem;
  }
`;

const SocialMediaContainer = styled.div`
  display: flex;
  margin: 1rem 0 0 0;
  svg {
    height: 20px;
    fill: #000000;
    margin: 0 0.25rem 0;
  }
`;

const CardWrapper = styled.div``;

const BioWrapper = styled.div`
  padding: 3rem 1rem 4rem;
  align-self: center;
  @media (min-width: 800px) {
    padding: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  @media (min-width: 800px) {
    grid-template-columns: 340px 1fr;
    grid-column-gap: 2rem;
  }
`;

const CoachCard = ({ data }) => {
  //
  const {
    name,
    featureImage: {
      asset: { fluid: feature },
    },
    profileImage: {
      asset: { fluid: profile },
    },
    bio,
    facebook,
    title,
    twitter,
    linkedIn,
    instagram,
  } = data.node;

  return (
    <Wrapper>
      <Grid>
        <CardWrapper>
          <CardContainer>
            <ProfileImageWrapper>
              <ImageSharp fluid={profile} />
            </ProfileImageWrapper>
            <BodyWrapper>
              <h2>{name}</h2>
              <p>{title}</p>
              <SocialMediaContainer>
                {facebook && (
                  <a href={facebook} target="_blank" rel="noopener noreferrer">
                    <Facebook />
                  </a>
                )}
                {instagram && (
                  <a href={instagram} target="_blank" rel="noopener noreferrer">
                    <Instagram />
                  </a>
                )}
                {linkedIn && (
                  <a href={linkedIn} target="_blank" rel="noopener noreferrer">
                    <LinkedIn />
                  </a>
                )}
                {twitter && (
                  <a href={twitter} target="_blank" rel="noopener noreferrer">
                    <Twitter />
                  </a>
                )}
              </SocialMediaContainer>
            </BodyWrapper>
          </CardContainer>
        </CardWrapper>
        <BioWrapper>
          <MarkdownStyles>
            <ReactMarkdown source={bio} />
          </MarkdownStyles>
        </BioWrapper>
      </Grid>
      <hr />
    </Wrapper>
  );
};

export default CoachCard;

CoachCard.propTypes = {
  data: PropTypes.object.isRequired,
};
